<template>
    <div class="container">
        <SendMessage />
    </div>
</template>
 
<script>

import SendMessage  from "../../views/communication/SendSms"
export default {
    components: { SendMessage   },
    setup() {
        
    },
}
</script>