<template>
    <div class="container">
        <!-- <div class="h1 mt-5">PAGE NOT FOUND </div> -->
        <router-view></router-view>
    </div>
</template>
 <script>
 export default {
     setup() {
         
     },
 }
 </script>

 <style scoped>
 
 </style>